import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { ExtendedTemplateComponent, ExtendedTemplateComponentConfig } from "src/stores/ExperienceTemplateStore/typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { CollectionItemClickthrough } from "@shared-ui/retail-incentives-collections";

export enum CardContentSize {
  FOUNDATION = "FOUNDATION",
  PADDED_CARD = "PADDED_CARD",
}

export interface CollectionPackagesCarouselConfig extends ExtendedTemplateComponentConfig {
  cardContentSize?: CardContentSize;
  collectionId: string;
  collectionItemClickthrough?: CollectionItemClickthrough;
  collectionItemsPageSize?: number;
  collectionItemsMaxPageSize?: number;
  hideCollectionHeaderTitle?: boolean;
  hideCollectionHeaderSubtitle?: boolean;
  hideCollectionHeaderDescription?: boolean;
  hideCollectionSummary?: boolean;
  hideCollectionTermsAndConditions?: boolean;
  hideCollectionCarouselCTA?: boolean;
  scrimHue?: number;
  scrimLightness?: number;
  scrimSaturation?: number;
  collectionGroupsPageSize?: number;
  collectionItemsPerGroupPageSize?: number;
}

export interface CollectionPackagesCarouselTemplateComponent extends ExtendedTemplateComponent {
  config: CollectionPackagesCarouselConfig;
}

export interface CollectionPackagesCarouselProps extends ComponentProps {
  context: ExtendedContextStore;
  templateComponent: CollectionPackagesCarouselTemplateComponent;
}
