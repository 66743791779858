import * as React from "react";
import { CardContentSize, CollectionPackagesCarouselProps } from "./typings";
import { CollectionPackagesCarousel as CollectionPackagesCarouselContainer } from "@shared-ui/retail-incentives-collections";
import { withStores } from "src/stores";
import { observer } from "mobx-react";

export const CollectionPackagesCarousel = withStores("context")(
  observer((props: CollectionPackagesCarouselProps) => {
    const {
      context: { searchContext },
      templateComponent,
    } = props;
    const {
      cardContentSize = CardContentSize.FOUNDATION,
      collectionId,
      collectionItemsPageSize,
      collectionItemsMaxPageSize,
      hideCollectionHeaderTitle,
      hideCollectionHeaderDescription,
      hideCollectionSummary,
      hideCollectionHeaderSubtitle,
      hideCollectionTermsAndConditions,
      hideCollectionCarouselCTA,
      collectionGroupsPageSize,
      collectionItemsPerGroupPageSize,
    } = templateComponent.config;

    /**
     * Generate placement input data for CollectionTiles
     * @see https://github.expedia.biz/eg-incentives/incentives-docs/blob/master/docs/dive/placements.md
     */
    const inputData = {
      collectionId,
      pageContext: {
        propertySearch: { primary: { destination: { regionId: searchContext?.location?.id?.toString() }, rooms: [] } },
      },
      paginationContext: {
        pageSize: collectionItemsPageSize,
        maxPageSize: collectionItemsMaxPageSize,
      },
      collectionGroupsPageSize,
      collectionItemsPerGroupPageSize,
    };

    /* istanbul ignore next */
    const hideAttributes = {
      hideHeaderTitle: hideCollectionHeaderTitle ?? false,
      hideHeaderSubtitle: hideCollectionHeaderSubtitle ?? false,
      hideHeaderDescription: hideCollectionHeaderDescription ?? false,
      hideSummary: hideCollectionSummary ?? false,
      hideTermsAndConditions: hideCollectionTermsAndConditions ?? false,
      hideCarouselCTA: hideCollectionCarouselCTA ?? false,
    };

    return (
      <CollectionPackagesCarouselContainer inputs={inputData} {...hideAttributes} cardContentSize={cardContentSize} />
    );
  })
);

export default CollectionPackagesCarousel;
